import {ieFix} from './utils/ie-fix';
import {iosVhFix} from './utils/ios-vh-fix';

import {initModals} from './modules/init-modals';
import {initValidate} from './modules/init-validate';
import {initChoices} from './modules/init-choices';
import {initMobileMenu} from './modules/init-mobile-menu';
import {initAccordion} from './modules/init-accordion';
import {initActiveInput} from './modules/init-active-input';
import {initPassword} from './modules/init-password';
import {cutTextOverflow} from './modules/cut-text-overflow';
import {dropdown} from './modules/dropdown';
import {fileUpload} from './modules/file-upload';

// Utils
// ---------------------------------

ieFix();
iosVhFix();

// Modules
// ---------------------------------

initModals();
initValidate();
initChoices();
initMobileMenu();
initAccordion();
initActiveInput();
initPassword();
cutTextOverflow();
dropdown();
fileUpload();
