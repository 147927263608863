const initActiveInput = () => {
  const inputs = document.querySelectorAll('input[data-id="change-input"]');
  const btns = document.querySelectorAll('button[data-id="change-input"');

  if (btns) {

    btns.forEach((item) => {
      item.addEventListener('click', function () {

        item.classList.add('visually-hidden');

        inputs.forEach((elem) => {
          elem.removeAttribute('disabled');
        });

      });
    });

  }

  const inputsInst = document.querySelectorAll('input[data-id="change-input-inst"]');
  const btnsInst = document.querySelectorAll('button[data-id="change-input-inst"');

  if (btnsInst) {

    btnsInst.forEach((item) => {
      item.addEventListener('click', function () {

        item.classList.add('visually-hidden');

        inputsInst.forEach((elem) => {
          elem.removeAttribute('disabled');
        });

      });
    });

  }

};

export {initActiveInput};
