const accordionQuestionsToggles = document.querySelectorAll('.questions__text--answered');
const accordionQuestions = document.querySelectorAll('.questions__item');

const showAccordionElement = (element) => {
  element.classList.remove('questions__item--closed');
};

const hideAccordionElement = (element) => {
  element.classList.add('questions__item--closed');
};

const showSupportBlock = (index) => {
  showAccordionElement(accordionQuestions[index]);
};

const closeSupportBlock = (index) => {
  hideAccordionElement(accordionQuestions[index]);
};

const initAccordion = () => {
  const markRead = (element) => {
    if (element.classList.contains('questions__text--unread')) {
      element.classList.remove('questions__text--unread');
    }
  };

  const accordionToggleHandler = (evt) => {
    const currentItem = evt.target.closest('.questions__item');
    if (!currentItem) {
      return;
    }

    if (currentItem.classList.contains('questions__item--closed')) {
      showAccordionElement(currentItem);
    } else {
      hideAccordionElement(currentItem);
    }
    markRead(evt.target.parentElement);
  };

  const accordionKeydownHandler = (evt) => {
    if (evt.key === 'Enter') {
      evt.preventDefault();
      accordionToggleHandler(evt);
    }
  };

  const setAccordionActive = (toggles) => {
    toggles.forEach((toggle) => {
      const accordionItem = toggle.closest('.questions__item');
      if (accordionItem) {
        toggle.addEventListener('click', accordionToggleHandler);
        toggle.addEventListener('keydown', accordionKeydownHandler);
      }
    });
  };

  setAccordionActive(accordionQuestionsToggles);
};

window.showSupportBlock = showSupportBlock;
window.closeSupportBlock = closeSupportBlock;

export {initAccordion, showSupportBlock, closeSupportBlock};
