const fileUpload = () => {
  const fileChoosers = document.querySelectorAll('[data-id="upload-file"]');
  const fileChoosersInner = document.querySelectorAll('[data-id="btn-upload-file"]');
  const buttonsRemovePicture = document.querySelectorAll('button[data-id="remove-picture"]');

  const changeVisibility = (element) => {
    const isInvisible = element.classList.contains('visually-hidden');
    if (isInvisible) {
      setTimeout(() => {
        element.classList.remove('visually-hidden');
      }, 150);
    } else {
      setTimeout(() => {
        element.classList.add('visually-hidden');
      }, 150);
    }
  };

  if (fileChoosers) {
    fileChoosers.forEach((chooser) => {
      const currentUploadForm = chooser.closest('.form__picture');
      const currentContentBeforeUpload = currentUploadForm.querySelectorAll('.js-content');
      const currentContentUpload = currentUploadForm.querySelectorAll('.js-content-upload');

      chooser.addEventListener('change', function () {
        currentContentBeforeUpload.forEach((item) => {
          changeVisibility(item);
        });

        currentContentUpload.forEach((item) => {
          changeVisibility(item);
        });
      });
    });
  }


  let FILE_TYPES = ['gif', 'jpg', 'jpeg', 'png'];

  const addFile = (chooser) => {
    const picture = chooser.closest('.form__picture').querySelector('.form__upload-img');
    const activeFileChooser = chooser;
    let file = activeFileChooser.files[0];
    let fileName = file.name.toLowerCase();

    let matches = FILE_TYPES.some(function (it) {
      return fileName.endsWith(it);
    });

    if (matches) {
      let reader = new FileReader();

      reader.addEventListener('load', function () {
        picture.src = reader.result;
      });

      reader.readAsDataURL(file);
    }
  };


  fileChoosers.forEach((chooser) => {
    chooser.addEventListener('change', function (evt) {
      addFile(evt.target);
    });
  });

  // inner
  fileChoosersInner.forEach((chooser) => {
    chooser.addEventListener('change', function (evt) {
      addFile(evt.target);
    });
  });


  buttonsRemovePicture.forEach((button) => {
    const currentUploadForm = button.closest('.form__picture');
    const currentContentBeforeUpload = currentUploadForm.querySelectorAll('.js-content');
    const currentContentUpload = currentUploadForm.querySelectorAll('.js-content-upload');

    button.addEventListener('click', function () {
      currentContentBeforeUpload.forEach((item) => {
        item.classList.remove('visually-hidden');
      });

      currentContentUpload.forEach((item) => {
        item.classList.add('visually-hidden');
      });
    });
  });
};

export {fileUpload};
