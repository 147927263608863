const initChoices = () => {
  let selectNodes = document.querySelectorAll('select');
  const selectList = [];

  if (selectNodes) {
    selectNodes.forEach((item) => {

      // eslint-disable-next-line no-undef
      let itemChoices = new Choices(item, {
        silent: true,
        removeItemButton: item.hasAttribute('multiple') ? true : false,
        searchEnabled: false,
        itemSelectText: '',
        renderSelectedChoices: 'always',
      });

      selectList.push(itemChoices);
    });

  } else if (selectNodes === null) {
    selectList = [];
    selectNodes = document.querySelector('select');

    // eslint-disable-next-line no-undef
    let itemChoices = new Choices(selectNodes, {
      removeItemButton: false,
      searchEnabled: false,
      itemSelectText: '',
    });

    selectList.push(itemChoices);
  }

};

window.initChoices = initChoices;

export {initChoices};
