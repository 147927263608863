// Открывает и закрывает списки
const dropdown = () => {
  let buttons = document.querySelectorAll('.js-dropdown');

  if (buttons) {
    buttons.forEach((btn) => {
      btn.addEventListener('click', function (evt) {
        // eslint-disable-next-line no-invalid-this
        this.nextElementSibling.classList.remove('visually-hidden');
        evt.preventDefault();
        btn.classList.add('visually-hidden');
      });
    });
  }
};

export {dropdown};
