const cutTextOverflow = () => {
  const MAX_MOBILE_TEXT_LENGTH = 76;
  const MAX_DESK_TEXT_LENGTH = 170;
  const textContainers = document.querySelectorAll('.questions__item');
  const textTogglers = document.querySelectorAll('.questions__text');
  const smallScreen = window.matchMedia('(max-width: 767px)');
  let uncuttedTexts = [];

  if (textContainers.length) {

    const saveUncuttedTexts = () => {
      textContainers.forEach((container, index) => {
        const textElement = container.querySelector('.questions__text p');

        if (textElement) {
          uncuttedTexts[index] = textElement.textContent;
        }
      });
    };

    const showCuttedText = (text, maxLimit, index) => {
      const cuttedText = uncuttedTexts[index].slice(0, maxLimit);
      text.textContent = cuttedText + '...';
    };

    const showFullText = (text, index) => {
      text.textContent = uncuttedTexts[index];
    };

    const changeText = (container, index) => {
      const textElement = container.querySelector('.questions__text p');
      const maxLimit = smallScreen.matches ? MAX_MOBILE_TEXT_LENGTH : MAX_DESK_TEXT_LENGTH;

      if (textElement && uncuttedTexts[index] && uncuttedTexts[index].length > maxLimit) {
        if (container.classList.contains('questions__item--closed')) {
          showCuttedText(textElement, maxLimit, index);
        } else {
          showFullText(textElement, index);
        }
      }
    };

    const changeTexts = () => {
      textContainers.forEach((container, index) => {
        changeText(container, index);
      });
    };

    saveUncuttedTexts();
    changeTexts();

    textTogglers.forEach((toggler) => {
      toggler.addEventListener('click', () => {
        changeTexts();
      });
    });

    if (smallScreen.addEventListener) {
      smallScreen.addEventListener('change', changeTexts);
    } else {
      smallScreen.addListener(changeTexts);
    }
  }
};

export {cutTextOverflow};
