import {setupModal} from '../utils/modal';

const modalDeleteProfile = document.querySelector('.modal--delete-profile');
const modalDeleteProfileBtns = document.querySelectorAll('[data-modal="delete-profile"]');

const modalAddWork = document.querySelector('.modal--add-work');
const modalAddWorkBtns = document.querySelectorAll('[data-modal="add-work"]');

const modalEditWork = document.querySelector('.modal--edit-work');
const modalEditWorkBtns = document.querySelectorAll('[data-modal="edit-work"]');

const modalDeleteWork = document.querySelector('.modal--delete-work');
const modalDeleteWorkBtns = document.querySelectorAll('[data-modal="delete-work"]');

const modalAddQuestion = document.querySelector('.modal--question');
const modalAddQuestionBtns = document.querySelectorAll('[data-modal="add-question"]');

const modalShowText = document.querySelector('.modal--show-text');
const modalShowTextBtns = document.querySelectorAll('[data-modal="show-text"]');

const modalSuccess = document.querySelector('.modal--add-work-success');


const initModals = () => {
  if (modalDeleteProfile && modalDeleteProfileBtns.length) {
    setupModal(modalDeleteProfile, false, modalDeleteProfileBtns, false, false);
  }
  if (modalAddWork && modalAddWorkBtns.length) {
    setupModal(modalAddWork, false, modalAddWorkBtns, false, false);
  }
  if (modalEditWork && modalEditWorkBtns.length) {
    setupModal(modalEditWork, false, modalEditWorkBtns, false, false);
  }
  if (modalDeleteWork && modalDeleteWorkBtns.length) {
    setupModal(modalDeleteWork, false, modalDeleteWorkBtns, false, false);
  }
  if (modalAddQuestion && modalAddQuestionBtns.length) {
    setupModal(modalAddQuestion, false, modalAddQuestionBtns, false, false);
  }
  if (modalShowText) {
    setupModal(modalShowText, false, modalShowTextBtns, false, false);
  }
  if (modalSuccess) {
    setupModal(modalSuccess, false, false, false, false);
  }
};

window.initModals = initModals;

export {initModals};
