import {disableScrolling, enableScrolling} from '../utils/scroll-lock';

const initMobileMenu = () => {
  const menuContainer = document.querySelector('.header');
  const menuToggles = document.querySelectorAll('.btn-open-menu, .header__menu-close');
  const smallScreen = window.matchMedia('(max-width: 1023px)');
  let isMenuOpened = true;

  if (!menuContainer || menuToggles.length <= 1) {
    return;
  }

  const changeMenuVisibility = () => {
    isMenuOpened = menuContainer.classList.contains('header--opened');
    changeScroll();
    if (isMenuOpened) {
      menuContainer.classList.remove('header--opened');
      setTimeout(() => {
        menuContainer.classList.add('header--closed');
      }, 500);
    } else {
      menuContainer.classList.remove('header--closed');
      setTimeout(() => {
        menuContainer.classList.add('header--opened');
      }, 50);
    }
  };

  const changeScroll = () => {
    if (!isMenuOpened && smallScreen.matches) {
      disableScrolling();
    } else {
      enableScrolling();
    }
  };

  if (smallScreen.addEventListener) {
    smallScreen.addEventListener('change', changeScroll);
  } else {
    smallScreen.addListener(changeScroll);
  }

  for (const toggle of menuToggles) {
    toggle.addEventListener('click', (evt) => {
      evt.preventDefault();
      changeMenuVisibility();

    });
  }

  menuContainer.addEventListener('click', (evt) => {
    if (evt.target === menuContainer) {
      changeMenuVisibility();
    }
  });
};

export {initMobileMenu};
