import {getValidate, notValid, valid} from '../utils/validate';

const initValidate = () => {

  const regLogin = /^[a-zA-Z0-9_.]{4,20}$/;
  const regName = /^[а-яa-z]/i;
  const regInstagram = /^[a-zA-Z0-9_.]{1,20}$/;
  const regEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regPassword = /^[a-zA-Z0-9_.]{4,20}$/;

  let inputs = document.querySelectorAll('input');

  if (inputs) {
    inputs.forEach((input) => {

      input.addEventListener('input', () => {

        let value = input.value;

        if (input.name === 'login') {

          if (!getValidate(regLogin, value)) {
            notValid(input);
          } else {
            valid(input);
          }

        } else if (input.name === 'username') {

          if (!getValidate(regName, value)) {
            notValid(input);
          } else {
            valid(input);
          }

        } else if (input.name === 'password') {

          if (!getValidate(regPassword, value)) {
            notValid(input);
          } else {
            valid(input);
          }

        } else if (input.name === 'instagram') {

          if (!getValidate(regInstagram, value)) {
            notValid(input);
          } else {
            valid(input);
          }

        } else if (input.name === 'email') {

          if (!getValidate(regEmail, value)) {
            notValid(input);
          } else {
            valid(input);
          }
        } else if (input.name === 'password-repeat') {
          let password = document.getElementById('password').value;
          let passwordRepeat = document.getElementById('password-repeat').value;

          if (password !== passwordRepeat) {
            notValid(input);
          } else {
            valid(input);
          }
        }

      });
    });
  }

};

export {initValidate};
