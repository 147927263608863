const getValidate = (regex, value) => {
  return regex.test(value);
};

const notValid = (input) => {
  input.classList.add('on-error');

  let id = input.name;
  let idBlock = 'error-message-' + id;
  let block = document.getElementById(idBlock);
  block.classList.remove('visually-hidden');
};

const valid = (input) => {
  input.classList.remove('on-error');

  let id = input.name;
  let idBlock = 'error-message-' + id;
  let block = document.getElementById(idBlock);
  block.classList.add('visually-hidden');
};

export {getValidate, notValid, valid};
