const initPassword = () => {
  let formItem = document.querySelector('.js-form-item-password');

  const hiddenPassword = (el, str) => {
    let arr = str.split('');
    let arrMasks = [];

    arr.forEach((item) => {
      arrMasks.push(item.replace(item, '*'));
    });

    let mask = arrMasks.join('');
    el.setAttribute('placeholder', mask);
  };

  if (formItem) {
    let input = formItem.querySelector('input');
    let password = input.getAttribute('placeholder');

    hiddenPassword(input, password);

    let btnActive = formItem.querySelector('button[data-id="password-hidden"]');
    let btnHidden = formItem.querySelector('button[data-id="password-active"]');

    btnActive.addEventListener('click', function (evt) {
      evt.preventDefault();
      btnActive.classList.toggle('visually-hidden');
      btnHidden.classList.toggle('visually-hidden');
      input.setAttribute('placeholder', password);
    });

    btnHidden.addEventListener('click', function (evt) {
      evt.preventDefault();
      btnActive.classList.toggle('visually-hidden');
      btnHidden.classList.toggle('visually-hidden');
      hiddenPassword(input, password);
    });
  }

};

export {initPassword};

